<template>
  <div v-if="$store.getters.isAdmin">
    <div class="adminNav">
      <edit-business-modal :business="business" @refetch="refetch"/>
      <add-location-modal @refetch="refetch"/>
      <image-upload
          :folder-path="`/${$route.params.businessID}/logo/`"
          label="Upload Logo"
          @save="saveImage"
      />
    </div>
  </div>
</template>

<script>
import {DELETE_IMAGE, UPDATE_BUSINESS_LOGO,} from "@/modules/business/graph/mutations";
import ImageUpload from "@/components/images/imageUpload";
import EditBusinessModal from "@/modules/business/components/editBusinessModal";
import AddLocationModal from "@/modules/location/AddLocationModal";

export default {
  name: "EditBusiness",
  components: {AddLocationModal, EditBusinessModal, ImageUpload},
  props: {
    business: {
      type: Object,
      required: true,
      default: () => {
      }
    },
  },
  emits: ["refetch"],
  methods: {
    saveImage(data) {
      const oldLogoID = this.business?.logo?.id;
      this.$apollo
          .mutate({
            mutation: UPDATE_BUSINESS_LOGO,
            variables: {id: this.$route.params.businessID, logo: data[0]},
          })
          .then(() => {
            if (oldLogoID) {
              this.$apollo.mutate({
                mutation: DELETE_IMAGE,
                variables: {id: oldLogoID},
              });
            }
          })
          .then(() => this.$emit("refetch"));
    },
    refetch() {
      this.$emit("refetch")
    },
    saveBusinessInfo(data) {
      console.log('save business info: ', data)
      // this.$apollo
      //   .mutate({
      //     mutation: UPDATE_BUSINESS,
      //     variables: { id: this.$route.params.businessID, _set: data },
      //   })
      //   .then(() => {
      //     this.$notify({
      //       group: "admin",
      //       type: "success",
      //       title: "Business Info Saved",
      //     });
      //     this.$emit("refetch");
      //   });
    },
  },
};
</script>
