<template>
  <v-row v-if="locations.length === 0" class="no-gutters">
    <v-col :cols="12" class="center"><h1>No available locations</h1></v-col>
  </v-row>
  <v-row v-else class="no-gutters">
    <v-col :lg="4" :md="3" :sm="12" :xs="12" cols="12">
      <v-sheet
          class="pr-2 pl-2"
          color="white"
          elevation="1"
      >
        <v-list>
          <v-row justify="space-between">
            <v-col lg="3" md="2" sm="2">
              <v-subheader>LOCATIONS</v-subheader>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="center" lg="3" md="5" sm="2">
              <add-location-modal v-if="$store.getters.isAdmin" button-name="Add" @refetch="refetch"/>
            </v-col>
          </v-row>
          <v-list-item-group
              v-model="selectedItem"
              color="primary"
          >
            <v-list-item
                v-for="(location) in locations"
                :key="location.id"
                @click="()=>locationClick(location)"
            >
              <v-list-item-content>
                <v-row>
                  <v-col cols="12">
                    <v-list-item-title>{{ location.name }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <div v-if="location.address">
                        <p v-if="location.address.address1" class="text-left mb-0">{{ location.address.address1 }}</p>
                        <p v-if="location.address.address2" class="text-left mb-0">{{ location.address.address2 }}</p>
                        <p class="text-left">
                          {{ location.address.city ? location.address.city : "" }},
                          {{ location.address.state ? location.address.state : "" }}
                          {{ location.address.postalCode ? location.address.postalCode : "" }}
                        </p>
                      </div>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <div v-if="location.phoneNumber" class="phone">
                        <a :href="`tel:${location.phoneNumber}`">{{
                            location.phoneNumber && location.phoneNumber | formatPhone
                          }}</a>
                      </div>
                    </v-list-item-subtitle>
                  </v-col>
                  <v-col v-if="$store.getters.isAdmin" cols="12">
                    <edit-location-modal :location="location"/>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-sheet>
    </v-col>
    <v-spacer></v-spacer>
    <v-col :lg="8" :md="9" :sm="12" :xs="12" cols="12">
      <gmap
          ref="mapRef"
          :center="center"
          :zoom="12"
          map-type-id="terrain"
          style="height: 500px"
      >
        <gmarker
            v-for="(m, index) in locationMarkers"
            :key="index"
            :clickable="true"
            :draggable="false"
            :position="m.position"
            :title="m.title"
            @click="toggleInfo(m, index)"
        >
          <g-info-window
              :content="m.infoContent"
              :opened="m.infoOpened"
              :options="m.infoOptions"
              :position="m.infoPosition"
              @closeclick="m.infoOpened=false"/>
        </gmarker>
      </gmap>
    </v-col>
  </v-row>
</template>
<script>
import Location from "@/modules/location/Location";
import AddLocationModal from "@/modules/location/AddLocationModal";
import EditLocationModal from "@/modules/location/EditLocationModal";

export default {
  name: "LocationLocator",
  components: {EditLocationModal, AddLocationModal, Location},
  props: {
    name: {
      type: String
    },
    locations: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  watch: {
    locations(newItem) {
      this.center = newItem[0]?.address?.position
          ? this.$props.locations[0].address.position
          : {lat: 38.568093175124154, lng: -121.477252587253};

      this.locationMarkers = newItem.map((location) => ({
        name: `${this.$props.name} (${location.name})`,
        position: {...location.address.position},
        infoPosition: null,
        infoContent: null,
        infoOpened: false,
        infoCurrentKey: null,
        infoOptions: {
          pixelOffset: {
            width: 0,
            height: 0
          },
          content:
              '<div id="content">' +
              '<div id="siteNotice">' +
              '</div>' +
              `<h1 id="firstHeading" class="firstHeading">${this.$props.name} </h1>` +
              '<div id="bodyContent">' +
              `<p>(${location.name})</p>` +
              '</div>' +
              '</div>'
        }

      }));
    },
  },
  data() {
    return {
      selectedItem: 0,
      center: {lat: 38.57315542550308, lng: -121.48107891498117},
      locationMarkers: [],
    };
  },
  mounted() {
    this.locationMarkers = this.$props.locations.map((location) => ({
      position: {...location.address.position},
      infoPosition: null,
      infoContent: null,
      infoOpened: false,
      infoCurrentKey: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        },
        content:
            '<div id="content">' +
            '<div id="siteNotice">' +
            '</div>' +
            '<h1 id="firstHeading" class="firstHeading">Uluru</h1>' +
            '<div id="bodyContent">' +
            '<p><b>Uluru</b>, also referred to as <b>Ayers Rock</b>, is a large ' +
            'sandstone rock formation in the southern part of the ' +
            'Northern Territory, central Australia. It lies 335&#160;km (208&#160;mi) ' +
            'south west of the nearest large town, Alice Springs; 450&#160;km ' +
            '(280&#160;mi) by road. Kata Tjuta and Uluru are the two major ' +
            'features of the Uluru - Kata Tjuta National Park. Uluru is ' +
            'sacred to the Pitjantjatjara and Yankunytjatjara, the ' +
            'Aboriginal people of the area. It has many springs, waterholes, ' +
            'rock caves and ancient paintings. Uluru is listed as a World ' +
            'Heritage Site.</p>' +
            '<p>Attribution: Uluru, <a href="https://en.wikipedia.org/w/index.php?title=Uluru&oldid=297882194">' +
            'https://en.wikipedia.org/w/index.php?title=Uluru</a> ' +
            '(last visited June 22, 2009).</p>' +
            '</div>' +
            '</div>'
      }

    }));
  },
  computed: {
    markers() {
      return this.$props.locations.map((location) => ({
        position: {...location.address.position},
      }));
    },
  },
  created() {
    this.center = this.$props?.locations[0]?.address?.position
        ? this.$props.locations[0].address.position
        : {lat: 38.568093175124154, lng: -121.477252587253};
  },
  methods: {
    toggleInfo(m, index) {
      this.locationMarkers[index].infoOpened = !m.infoOpened
    },
    editLocationData(location) {
      delete location.approvalState
      delete location.social
      delete location.__typename
      return location
    },
    locationClick(location) {
      this.$refs.mapRef.$mapPromise.then((map) => {
        map.panTo(location?.address?.position);
      });
    },
    refetch() {
      this.$emit('refetch')
    }
  },
};
</script>
