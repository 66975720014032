<template>
  <v-dialog
      v-model="dialog"
      width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          small
          color="secondary"
          dark
          v-bind="attrs"
          v-on="on"
      >
        Delete
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Delete Business
      </v-card-title>

      <v-card-text>
        Are you sure you want to delete {{ business.name }}?.
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="!loading"
            color="primary"
            text
            @click="click"
        >
          Confirm Delete
        </v-btn>
        <v-btn v-else
               color="primary"
               text
        >
          <v-progress-circular indeterminate :value="20"></v-progress-circular>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "ConfirmDeleteModal",
  props: {
    loading:{
      type: Boolean,
      required:true,
      default:false
    },
    business: {
      type: Object,
      required: true,
      default: {},
    },
    handleDelete:{
      type: Function,
      required: true,
      default: ()=>{}
    }
  },
  data(){
    return{dialog:false}
  },
  methods: {
    async click() {
      await this.handleDelete(this.business.id)
      this.dialog = false;
    },
  },
};
</script>
<style scoped>
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
}
</style>
