import axios from "axios";

const BASE_URL = "https://upload.imagekit.io";

function upload(formData) {
  const url = `${BASE_URL}/api/v1/files/upload`;
  return axios.post(url, formData).then((file) => file.data);
}

function getToken() {
  return axios.get("https://beta.drinksy.app/api/imagekit");
}

export { upload, getToken };
