import Vue from "vue";
import router from "./router";
import {apolloProvider} from "./apollo";
import App from "./App.vue";
import VModal from "vue-js-modal";
import "@/assets/reset.css";
import "@/assets/styles.css";
import ToggleButton from "vue-js-toggle-button";
import Notifications from "vue-notification";
import {ValidationProvider} from "vee-validate";
import {formatPhone} from "@/filters/phoneNumber";
import ImageKit from "imagekitio-vue";
import * as VueGoogleMaps from "vue2-google-maps";
import "vue-awesome/icons/map-marker-alt";
import "vue-awesome/icons/map";
import "vue-awesome/icons/edit";
import Icon from "vue-awesome/components/Icon";
// Import the auth configuration
import {clientId, domain} from "./auth/auth_config.json";

// Import the plugin here
import VueMeta from 'vue-meta'
import {Auth0Plugin} from "./auth";
import store from "./store";
import VueHead from "vue-head";
import vuetify from "./plugins/vuetify";
import Ads from "vue-google-adsense";

Vue.config.productionTip = false;

Vue.component("ValidationProvider", ValidationProvider);

Vue.use(require("vue-script2"));
Vue.use(Ads.Adsense);
Vue.use(Ads.InArticleAdsense);
Vue.use(Ads.InFeedAdsense);

Vue.filter("formatPhone", formatPhone);
// Install the authentication plugin here
Vue.use(VueMeta, {
    // optional pluginOptions
    refreshOnceOnNavigation: true
})
Vue.use(Auth0Plugin, {
    domain,
    clientId,
    onRedirectCallback: (appState) => {
        router.push(
            appState && appState.targetUrl
                ? appState.targetUrl
                : window.location.pathname
        );
    },
});
Vue.use(VueHead);
Vue.use(ImageKit, {
    urlEndpoint: "https://ik.imagekit.io/drinksy/", // Required. Default URL-endpoint is https://ik.imagekit.io/your_imagekit_id
    publicKey: "public_6j9qxF2vMiK8c1j2SkP/6DGlqnU=", // optional
    authenticationEndpoint: "https://beta.drinksy.app/api/imagekit", // optional
    // transformationPosition: "path" // optional
});
Vue.use(VModal, {componentName: "Modal"});
Vue.use(Notifications);
Vue.use(ToggleButton);
Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyAw_uB5SQik7A8jaCCEA98dSvgiN_VRaY8",
        // libraries: 'places', // This is required if you use the Autocomplete plugin
        libraries: "places,drawing",
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

        //// If you want to set the version, you can do so:
        // v: '3.26',
    },

    //// If you intend to programmatically custom event listener code
    //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    //// you might need to turn this on.
    // autobindAllEvents: false,

    //// If you want to manually install components, e.g.
    //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
    //// Vue.component('GmapMarker', GmapMarker)
    //// then disable the following:
    installComponents: true,
});
Vue.component("Gmap", VueGoogleMaps.Map);
Vue.component("Gmarker", VueGoogleMaps.Marker);
Vue.component("GInfoWindow", VueGoogleMaps.InfoWindow);

Vue.component("VIcon", Icon);

new Vue({
    router,
    apolloProvider,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");
