<template>
  <Fragment>
    <v-app-bar app clipped-left dense style="z-index: 30">
      <v-app-bar-nav-icon class="d-flex d-sm-none"
                          @click="drawer = true"
      >
      </v-app-bar-nav-icon>
      <v-row>
        <v-btn class="button d-none d-sm-flex" text @click="$router.push('/')">
          Home
        </v-btn>
        <v-btn class="button d-none d-sm-flex" text @click="$router.push('/map')">
          Map
        </v-btn>
        <v-spacer></v-spacer>
        <div class="d-flex align-center text-h5">
          <router-link :to="{ name: 'home' }" class="logo" style="color: #ff3e00">Drinksy</router-link>
        </div>
        <v-spacer></v-spacer>
        <div class="d-none d-sm-flex">
          <router-link
              v-if="$store.getters.isAdmin && isAuthenticated"
              v-slot="{ navigate }"
              :to="{ name: 'adminDashboard' }"
              custom
          >
            <v-btn class="button d-none d-sm-flex" text @click="navigate">
              Admin
            </v-btn>
          </router-link>
          <router-link
              v-if="isAuthenticated"
              v-slot="{ navigate }"
              :to="{ name: 'profile' }"
              custom
              tag="button"
          >
            <v-btn class="button d-none d-sm-flex" role="link" text @click="navigate">
              Profile
            </v-btn>
          </router-link>
          <v-btn
              v-if="!isAuthenticated"
              class="button d-none d-sm-flex" text
              @click="login"
          >
            Log in
          </v-btn>
          <v-btn
              v-if="isAuthenticated"
              class="button d-none d-sm-flex" text
              @click="logout"
          >
            Log out
          </v-btn>
        </div>
      </v-row>
    </v-app-bar>
    <!-- Add a navigation drawer -->
    <v-navigation-drawer
        v-model="drawer"
        :style="`padding-top: ${$vuetify.application.top}px`"
        app
        clipped
    >
      <v-list
          dense
          nav
          style="display: flex; flex-direction: column; justify-content: space-between; height: 100%;"
      >
        <v-list-item-group color="primary">
          <v-list-item
              v-for="(item, i) in navItems"
              :key="i"
              :to="item.path"
              link
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <!--        <v-spacer></v-spacer>-->
        <v-list-item-group>
          <v-list-item v-if="$store.getters.isAdmin" link to="/admin">
            <v-list-item-icon>
              <v-icon>mdi-shield-crown-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Admin</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="isAuthenticated" link to="/profile">
            <v-list-item-icon>
              <v-icon>mdi-account-circle-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Profile</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="isAuthenticated" @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="!isAuthenticated" @click="login">
            <v-list-item-icon>
              <v-icon>mdi-account-circle-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Login</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </Fragment>
</template>

<script>
export default {
  name: "Navigation",
  data() {
    return {
      drawer: false,
      navItems: [
        {text: 'Home', icon: 'mdi-home', path: '/'},
        {text: 'Map', icon: 'mdi-google-maps', path: '/map'}
      ]
    }
  },
  mounted() {
    this.$store.dispatch("userCheck");
  },
  computed: {
    isAuthenticated() {
      console.log('Authenticated: ', this.$auth.isAuthenticated)
      return this.$auth.isAuthenticated
    }
  },
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      window.localStorage.clear();
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
  },
};
</script>
