<template>
  <div>
    <p v-if="$store.getters.isAdmin" class="approval-status">
      <select-approval-status
        :id="location.id"
        :approval-state="location.approvalState"
        @change-approval="changeApproval"
      />
    </p>
    <h2>{{ location.name }}</h2>
    <div class="locationDetails">
      <div v-if="location.phoneNumber" class="phone">
        <a :href="`tel:${location.phoneNumber}`">{{
          location.phoneNumber && location.phoneNumber | formatPhone
        }}</a>
      </div>
      <div v-if="location.address" class="address">
        <p v-if="location.address.address1">{{ location.address.address1 }}</p>
        <p v-if="location.address.address2">{{ location.address.address2 }}</p>
        <p>
          {{ location.address.city ? location.address.city : "" }},
          {{ location.address.state ? location.address.state : "" }}
          {{ location.address.postalCode ? location.address.postalCode : "" }}
        </p>
      </div>
      <!--      <div class="icon-group">-->
      <!--        <a :href="appleUrl(location.address)" class="icon"> <v-icon name="map" scale="2"/>-->
      <!--        </a>-->
      <!--        <a :href="googleUrl(location.address)" class="icon"> <v-icon name="map-marker-alt" scale="2"/>-->
      <!--        </a>-->
      <!--      </div>-->
    </div>
  </div>
</template>
<script>
import SelectApprovalStatus from "@/components/modals/selectApprovalStatus";
import { UPDATE_LOCATION_APPROVAL_STATE } from "@/components/modals/graph/mutations";

export default {
  name: "Location",
  components: { SelectApprovalStatus },
  props: {
    location: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  emits: ["refetch"],
  methods: {
    googleUrl(location) {
      const url = `https://www.google.com/maps/place/${location.address1}+${location.city}+${location.state}+${location.postalCode}`;
      return url.replace(/\s/g, "+");
    },
    appleUrl(location) {
      const url = `http://maps.apple.com/?address=${location.address1}+${location.city}+${location.state}+${location.postalCode}`;
      return url.replace(/\s/g, "+");
    },
    changeApproval(value) {
      console.log("value", value);
      this.$apollo
        .mutate({
          mutation: UPDATE_LOCATION_APPROVAL_STATE,
          variables: {
            id: value.id,
            state: value.state,
          },
        })
        .then(() => {
          this.$emit("refetch");
          this.$notify({
            group: "admin",
            type: "success",
            title: "Approval State Updated",
          });
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            group: "admin",
            type: "error",
            title: "Approval State Update Failed",
          });
        });
    },
  },
};
</script>
<style scoped>
.locationDetails {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.phone {
  display: flex;
  margin-bottom: 10px;
}

.address {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
</style>
