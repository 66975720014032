import Vue from "vue";
import VueApollo from "vue-apollo";
import apolloClient from "@/apollo/apolloClient.js";

Vue.use(VueApollo);
let loading = 0;
const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    // $loadingKey: 'loading',
  },
  watchLoading(state, mod) {
    loading += mod;
    return loading;
  },
  errorHandler(error) {
    console.log("Error");
    console.warn(JSON.stringify(error, null, 2));
  },
});

export default apolloProvider;
