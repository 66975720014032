import { authGuard } from "@/auth/authGuard";

export default [
  {
    path: "/admin",
    component: () => import("@/modules/admin/Admin"),
    beforeEnter: authGuard,
    children: [
      {
        path: "/",
        name: "adminDashboard",
        component: () => import("@/modules/admin/AdminDashboard"),
      },
      {
        path: "/admin/business",
        name: "adminBusiness",
        component: () => import("@/modules/admin/AdminBusiness"),
      },
      {
        path: "/admin/users",
        name: "adminUsers",
        component: () => import("@/modules/admin/AdminUser"),
      },
    ],
  },
];
