<template>
  <label>
    <toggle-button
      v-model="closed"
      :value="business.closed"
      :color="{
        checked: '#f44336',
        unchecked: '#4caf50',
        disabled: '#CCCCCC',
      }"
      :width="60"
      :labels="{ checked: 'Closed', unchecked: 'Open' }"
      @change="onChangeEventHandler(business, closed)"
    />
  </label>
</template>
<script>
import { UPDATE_DISABLE_BUSINESS } from "@/modules/business/graph/mutations";

export default {
  name: "ToggleClosedState",
  props: {
    business: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      closed: this.$props.business.closed,
    };
  },
  methods: {
    onChangeEventHandler: function (business, status) {
      console.log("CLOSED: ", closed);
      this.$apollo
        .mutate({
          mutation: UPDATE_DISABLE_BUSINESS,
          variables: {
            id: business.id,
            closed: status,
          },
        })
        .then(() => {
          this.$notify({
            group: "admin",
            type: "success",
            title: "Disable Status Updated",
          });
          this.$emit("refetch");
        })
        .catch(() => {
          this.$notify({
            group: "admin",
            type: "error",
            title: "Disable Status Update Failed",
          });
        });
    },
  },
};
</script>
