import gql from "graphql-tag";

export const INSERT_IMAGES = gql`
  mutation InsertImages($objects: [image_insert_input!]!) {
    insert_image(objects: $objects) {
      returning {
        id
      }
    }
  }
`;
