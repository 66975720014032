<template>
  <v-row v-else class="no-gutters">
    <v-col :xs="12" cols="12">
      <v-sheet
          class="pr-2 pl-2 mr-3 ml-3"
          color="white"
          elevation="1"
      >
        <v-row>
          <v-col :lg="4" :md="3" :sm="12" :xs="12" cols="12">
            <v-list>
              <v-row justify="space-between">
                <v-col lg="3" md="2" sm="2">
                  <v-subheader>LOCATIONS</v-subheader>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="center" lg="3" md="5" sm="2">
                  <add-location-modal v-if="$store.getters.isAdmin" button-name="Add" @refetch="refetch"/>
                </v-col>
              </v-row>
              <v-list-item-group
                  v-model="selectedItem"
                  color="primary"
              >
                <v-list-item
                    v-for="(location) in locations"
                    :key="location.id"
                    @click="()=>locationClick(location)"
                >
                  <v-list-item-content>
                    <v-row>
                      <v-col cols="12">
                        <v-list-item-title>{{ location.name }}</v-list-item-title>
                        <v-list-item-subtitle>
                          <div v-if="location.address">
                            <p v-if="location.address.address1" class="text-left mb-0">{{
                                location.address.address1
                              }}</p>
                            <p v-if="location.address.address2" class="text-left mb-0">{{
                                location.address.address2
                              }}</p>
                            <p class="text-left">
                              {{ location.address.city ? location.address.city : "" }},
                              {{ location.address.state ? location.address.state : "" }}
                              {{ location.address.postalCode ? location.address.postalCode : "" }}
                            </p>
                          </div>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <div v-if="location.phoneNumber" class="phone">
                            <a :href="`tel:${location.phoneNumber}`">{{
                                location.phoneNumber && location.phoneNumber | formatPhone
                              }}</a>
                          </div>
                        </v-list-item-subtitle>
                      </v-col>
                      <v-col v-if="$store.getters.isAdmin" cols="12">
                        <edit-location-modal :location="location"/>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col :lg="8" :md="9" :sm="12" :xs="12" cols="12">
            <h1>Info here</h1>
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "Locations",
  props: {
    locations: {
      type: Object,
      required: true,
      default: {}
    }
  }
}
</script>