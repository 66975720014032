import gql from "graphql-tag";

export const GET_BUSINESS_BY_PK = gql`
  query GetBusinessByPK($id: uuid!) {
    business_by_pk(id: $id) {
      id
      name
      logo {
        id
        imagePath
        imageUrl
      }
      approvalState
      categories {
        category {
          name
        }
      }
      social {
        id
        type
        parent
        handle
      }
      locations(where: { approvalState: { _eq: APPROVED } }) {
        id
        name
        phoneNumber
        approvalState
        main
        address {
          id
          address1
          address2
          city
          state
          postalCode
          position
        }
        social {
          id
          type
          url
          handle
        }
      }
    }
  }
`;
export const GET_BUSINESS_BY_PK_ADMIN = gql`
  query GetBusinessByPK($id: uuid!) {
    business_by_pk(id: $id) {
      id
      name
      logo {
        id
        imagePath
        imageUrl
      }
      approvalState
      categories {
        category {
          name
        }
      }
      social {
        id
        type
        parent
        handle
      }
      locations {
        id
        name
        phoneNumber
        approvalState
        main
        address {
          id
          address1
          address2
          city
          state
          postalCode
          position
        }
        social {
          id
          url
          type
          handle
        }
      }
    }
  }
`;

export const GET_LOCATION_BY_PK = gql`
  query GetLocationByPK($id: uuid!) {
    location_by_pk(id: $id) {
      id
      name
      phoneNumber
      approvalState
    }
  }
`;
