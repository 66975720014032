<template>
  <v-dialog
      v-model="dialog"
      width="600"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn @click="dialog = true">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Edit Location
      </v-card-title>
      <v-form ref="form">
        <v-container>
          <v-row>
            <v-col>
              <div class="text-h5">Info</div>
              <v-text-field v-model="name" label="Name"/>
              <v-text-field type="phone" v-model="phoneNumber" label="Phone"/>
              <v-checkbox v-model="mainLocation" label="Main Location"></v-checkbox>
            </v-col>
            <v-col>
              <div class="text-h5">Address</div>
              <v-text-field v-model="street1" label="Street 1"/>
              <v-text-field v-model="street2" label="Street 2 (Optional)"/>
              <v-text-field v-model="city" label="City"/>
              <v-text-field v-model="state" label="State"/>
              <v-text-field v-model="postalCode" label="Postal Code"/>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="secondary"
            @click="cancel"
        >
          Cancel
        </v-btn>
        <v-btn v-if="!loading"
               color="primary"
               type="submit"
               @click="submit"
        >
          Submit
        </v-btn>
        <v-btn v-else
               color="primary"
               text
        >
          <v-progress-circular indeterminate :value="20"></v-progress-circular>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {UPDATE_LOCATION} from "@/components/modals/graph/mutations";
import {UPDATE_ADDRESS} from "@/modules/business/graph/mutations";

export default {
  name: "EditLocationModal",
  props: {
    location: {
      type: Object,
      required: true,
      default: () => {
      }
    }
  },
  data: function () {
    return {
      loading: false,
      dialog: false,
      name: '',
      phoneNumber: null,
      street1: '',
      street2: '',
      city: '',
      state: '',
      postalCode: '',
      mainLocation: false,
    };
  },
  watch: {
    location: {
      immediate: true,
      handler(newItem, oldLocation) {
        this.name = newItem?.name ? newItem?.name : '';
        this.phoneNumber = newItem?.phoneNumber ? newItem?.phoneNumber : null;
        this.street1 = newItem?.address?.address1 ? newItem?.address?.address1 : '';
        this.street2 = newItem?.address?.address2 ? newItem?.address?.address2 : '';
        this.city = newItem?.address?.city ? newItem?.address?.city : '';
        this.state = newItem?.address?.state ? newItem?.address?.state : '';
        this.postalCode = newItem?.address?.postalCode ? newItem?.address?.postalCode : '';
        this.mainLocation = newItem?.main ? newItem?.main : false;
      }
    }
  },
  methods: {
    async updateLocation() {
      this.loading = true
      await this.$apollo.mutate({
        mutation: UPDATE_LOCATION,
        variables: {
          id: this.$props.location.id,
          _set:{
            main: this.mainLocation,
            name: this.name,
            phoneNumber: this.phoneNumber
          }
        },
      }).catch(() => {
        this.loading = false
      })

      const addressObject = {
          address1: this.street1 ? this.street1 : '',
          address2: !this.street2 ? this.street2 : '',
          city: this.city ? this.city : '',
          state: this.state ? this.state : '',
          postalCode: this.postalCode ? this.postalCode : '',
      };
      await this.$apollo.mutate({
        mutation: UPDATE_ADDRESS,
        variables: {
          id: this.location.address.id,
          _set: addressObject
        },
      }).catch(() => {
        this.loading = false
      })
      this.loading = false
    },

    submit() {
      this.updateLocation().finally(() => {
        this.$emit("refetch");
        this.cancel()
      });
    },
    cancel() {
      this.$refs.form.reset()
      this.dialog = false
    },
  },

}
</script>