<template>
    <v-select
        v-model="state"
        :items="items"
        item-text="text"
        item-value="value"
        label="Select"
        @change="changeApproval"  class="text-center">
    </v-select>
</template>
<script>
export default {
  name: "SelectApprovalStatus",
  emit: ["changeApproval"],
  props: {
    id: {
      type: String,
      required: true,
      default: "",
    },
    approvalState: {
      type: String,
      required: true,
      default: "PENDING",
    },
  },
  data() {
    return {
      state: this.$props.approvalState,
      items: [
        { text: 'Approved', value: 'APPROVED' },
        { text: 'Pending', value: 'PENDING' },
        { text: 'Rejected', value: 'REJECTED' },
      ],
    };
  },
  methods: {
    changeApproval: function () {
      this.$emit("change-approval", { id: this.$props.id, state: this.state });
    },
  },
};
</script>
