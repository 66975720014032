import {ApolloClient} from "apollo-client";
import {createHttpLink} from "apollo-link-http";
import {InMemoryCache} from "apollo-cache-inmemory";
import {WebSocketLink} from "apollo-link-ws";
import {onError} from "apollo-link-error";
import {getMainDefinition} from "apollo-utilities";
import {setContext} from "apollo-link-context";
import {ApolloLink, split} from "apollo-link";
import {getInstance} from "@/auth/index.js";
import VueJwtDecode from "vue-jwt-decode";

const getHeaders = async () => {
    const headers = {};
    const authService = getInstance();

    const getToken = async () => {
        if (window.localStorage.getItem("token")) {
            let token = window.localStorage.getItem("token");
            const decoded = VueJwtDecode.decode(token);
            headers.Authorization = `Bearer ${token}`;
            headers["x-hasura-role"] =
                decoded["https://hasura.io/jwt/claims"]["x-hasura-role"];
            return headers;
        }
        // const token = await authService.getTokenSilently();
        const claims = await authService.getIdTokenClaims();
        if (claims) {
            const token = claims.__raw;
            console.log("apollo claims: ", token);
            window.localStorage.setItem("token", token);
            headers.authorization = `Bearer ${token}`;
        }
        return headers;
    };

    // If loading has already finished, check our auth state using `getToken()`
    if (!authService.loading) {
        return getToken();
    }

    // Watch for the loading property to change before we check isAuthenticated
    authService.$watch("loading", (loading) => {
        if (loading === false) {
            return getToken();
        }
    });
};

const options = {
    httpUri: process.env.VUE_APP_HASURA_URL,
    wsUri: process.env.VUE_APP_HASURA_WS,
};

// HTTP connection to the API
let HttpLink = new createHttpLink({
    // You should use an absolute URL here
    uri: options.httpUri,
});

// Create the subscription websocket link if available
if (options.wsUri) {
    const wsLink = new WebSocketLink({
        uri: options.wsUri,
        options: {
            reconnect: true,
            reconnectionAttempts: 5,
            lazy: true,
            connectionParams: async () => {
                return {headers: await getHeaders()};
            },
        },
    });

    // using the ability to split links, you can send data to each link
    // depending on what kind of operation is being sent
    HttpLink = split(
        // split based on operation type
        ({query}) => {
            const definition = getMainDefinition(query);
            return (
                definition.kind === "OperationDefinition" &&
                definition.operation === "subscription"
            );
        },
        wsLink,
        HttpLink
    );
}
const authLink = setContext(async () => {
    // get the authentication token from local storage if it exists
    // const token = localStorage.getItem("authorization_token");
    // return the headers to the context so httpLink can read them
    return {
        headers: await getHeaders(),
    };
});

const errorLink = onError(
    async ({graphQLErrors, networkError, operation, forward}) => {
        const authService = getInstance();
        // const newToken = await authService.getTokenSilently();
        // console.log("ERROR LINK: ", newToken)
        // window.localStorage.setItem("token", newToken);
        const token = await authService.getTokenSilently();
        window.localStorage.setItem("token", token)
        if (graphQLErrors) {
            graphQLErrors.forEach(({message}) => {
                console.log("GRAPHQL ERROR", message);
                const oldHeaders = operation.getContext().headers;

                // let token = window.localStorage.getItem("token");

                operation.setContext({
                    headers: {
                        ...oldHeaders,
                        authorization: token,
                    },
                });
                // retry the request, returning the new observable
                return forward(operation);
            });
        }

        if (networkError) {
            console.log("Network error", networkError);
        }
    }
);

// Cache implementation
const cache = new InMemoryCache();

const link = ApolloLink.from([errorLink, authLink, HttpLink]);

// Create the apollo client
const apolloClient = new ApolloClient({
    link,
    cache,
});
export default apolloClient;
