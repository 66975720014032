<template>
  <v-text-field
      label="Search"
      v-model="field"
      @input="search"
      clearable
  ></v-text-field>
</template>
<script>
import Business from "@/modules/business/Business";

export default {
  name: "Search",
  components: {Business},
  emit: ["search"],
  data() {
    return {
      field: "",
    };
  },
  methods: {
    search() {
      this.$emit("search", this.field);
    },
  },
};
</script>