import gql from "graphql-tag";

export const CREATE_BUSINESS = gql`
  mutation InsertBusiness($objects: [business_insert_input!]!) {
    insert_business(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_BUSINESS_APPROVAL_STATE = gql`
  mutation UpdateBusinessApprovalState($id: uuid, $state: approvalState_enum) {
    update_business(
      where: { id: { _eq: $id } }
      _set: { approvalState: $state }
    ) {
      affected_rows
    }
  }
`;
export const UPDATE_LOCATION_APPROVAL_STATE = gql`
  mutation UpdateLocationApprovalState($id: uuid, $state: approvalState_enum) {
    update_location(
      where: { id: { _eq: $id } }
      _set: { approvalState: $state }
    ) {
      affected_rows
    }
  }
`;

export const INSERT_LOCATION = gql`
  mutation InsertLocation($object: location_insert_input!) {
    insert_location_one(object: $object) {
      id
    }
  }
`;

export const UPDATE_LOCATION = gql`
  mutation UpdateLocation($id: uuid!, $_set: location_set_input) {
    update_location_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
  }
`;

export const INSERT_ADDRESS = gql`
  mutation InsertAddress($object: address_insert_input!) {
    insert_address_one(object: $object) {
      id
    }
  }
`;

export const UPDATE_ADDRESS = gql`
  mutation UpdateAddress($id: uuid!, $_set: address_set_input) {
    update_address_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
  }
`;

export const DELETE_BUSINESS = gql`
  mutation DeleteBusiness($id: uuid!) {
    delete_business_by_pk(id: $id) {
      id
    }
  }
`;

export const UPSERT_SOCIAL_LINKS = gql`
  mutation UpsertSocialLinks(
    $on_conflict: socialLinks_on_conflict = {
      constraint: socialLinks_pkey1
      update_columns: handle
    }
    $objects: [socialLinks_insert_input!]!
  ) {
    insert_socialLinks(objects: $objects, on_conflict: $on_conflict) {
      affected_rows
    }
  }
`;

export const INSERT_SOCIAL_LINKS = gql`
  mutation InsertSocialLinks($objects: [socialLinks_insert_input!]!) {
    insert_socialLinks(objects: $objects) {
      affected_rows
    }
  }
`;

// {
//   address: {
//     data: {
//       address1: ""
//       address2: ""
//       address3: ""
//       city: ""
//       postalCode: ""
//       state: ""
//     }
//   }
//   businessID: ""
//   category: ""
//   main: false
//   name: ""
//   phoneNumber: ""
//   social: { data: { name: "", url: "" } }
// }
