<template>
  <!--    <div-->
  <!--      v-if="business.approvalState === 'PENDING'"-->
  <!--      class="corner-ribbon top-right red shadow"-->
  <!--    >-->
  <!--      PENDING-->
  <!--    </div>-->
  <v-card @click="()=>$router.push(`/business/${business.id}`)">
    <v-img
        :src="imagePath"
        aspect-ratio="1"
        class="white--text align-end"
        contain
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        height="200px"
        lazy-src="/images/beer.png"
        transition="fade-transition"
    >
    </v-img>
    <v-card-actions>
      <v-card-title v-text="business.name"></v-card-title>
    </v-card-actions>
  </v-card>
  <!--    <div class="card-img">-->
  <!--      <div v-if="imagePath">-->
  <!--        <ik-image-->
  <!--          :path="imagePath"-->
  <!--          focus="auto"-->
  <!--          :transformation="[{ height: '200', width: '300' }]"-->
  <!--          loading="lazy"-->
  <!--          quality="50"-->
  <!--          :alt="business.name"-->
  <!--          :lqip="{ active: true }"-->
  <!--        />-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <div class="name">{{ business.name }}</div>-->
  <!--    <div-->
  <!--      v-for="(item, index) in showCategory(business.categories)"-->
  <!--      :key="index"-->
  <!--      class="category"-->
  <!--    >-->
  <!--      {{ item }}-->
  <!--    </div>-->
</template>
<script>
export default {
  name: "BusinessCard",
  props: {
    business: {
      type: Object,
      required: true,
    },
  },
  computed: {
    imagePath() {
      return this.business?.logo?.imageUrl;
    },
  },
  methods: {
    showCategory(categories) {
      let category = [];
      if (categories.length > 0) {
        for (let i = 0; i < categories.length; i++) {
          let name = categories[i].category.name.toLowerCase();
          if (name === "wine") {
            category.push("🍷");
          }
          if (name === "beer") {
            category.push("🍺");
          }
          if (name === "whisky") {
            category.push("🥃");
          }
        }
      }
      return category;
    },
  },
};
</script>
