<template>
  <v-app>
    <Navigation/>
    <v-main>
      <v-container class="pa-0" fluid>
        <router-view/>
        <Footer/>
      </v-container>
    </v-main>
    <notifications group="admin" position="bottom left"/>
  </v-app>
</template>

<script>
import {Footer, Navigation} from "@/components";

export default {
  name: "App",
  components: {Footer, Navigation},
  metaInfo: {
    // all titles will be injected into this template
    titleTemplate: 'Drinksy | %s'
  },
  data: function () {
    return {
      user: this.$auth.user,
    };
  },
};
</script>
