<template>
  <Fragment>
    <BusinessHeader :business="business" @edit="editItem"/>
    <v-row v-if="$store.getters.isAdmin" class="grey lighten-3 center">
      <v-col cols="12">
        <edit-business :business="business" @refetch="refetch"/>
      </v-col>
    </v-row>

    <SocialRow :socials="business.social"/>
    <!--    <Locations :locations="business.locations"/>-->
    <LocationLocator :locations="business.locations" :name="business.name" @refetch="refetch"/>
  </Fragment>
</template>
<script>
import {GET_BUSINESS_BY_PK, GET_BUSINESS_BY_PK_ADMIN,} from "@/modules/business/graph/queries";
import Location from "@/modules/location/Location";
import EditBusiness from "@/modules/business/EditBusiness";
import {mapGetters} from "vuex";
import BusinessHeader from "@/modules/business/components/BusinessHeader";
import LocationLocator from "@/components/locator/LocationLocator";
import SocialRow from "@/components/SocialRow";
import Locations from "@/modules/location/Locations";

export default {
  name: "Business",
  components: {
    Locations,
    SocialRow,
    LocationLocator,
    BusinessHeader,
    EditBusiness,
    Location,
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: `${this.business.name}`,
    }
  },
  data: function () {
    return {
      fab: false,
      business: {},
      edit: false,
    };
  },
  computed: {
    ...mapGetters(["isAdmin"]),
    businessLogoId() {
      return this.business?.logo?.id ? this.business?.logo?.id : "";
    },
  },
  mounted() {
    this.refetch();
    this.$on("refetch", this.refetch);
  },
  methods: {
    refetch() {
      this.$apollo.queries.business.refetch();
    },
    editItem() {
      this.edit = !this.edit;
    },
  },
  apollo: {
    business: {
      fetchPolicy: "cache-and-network",
      query() {
        return this.isAdmin ? GET_BUSINESS_BY_PK_ADMIN : GET_BUSINESS_BY_PK;
      },
      variables() {
        return {
          id: this.$route.params.businessID,
        };
      },
      skip() {
        return !this.$route.params.businessID;
      },
      update: (data) => data.business_by_pk,
      error(error) {
        this.$notify({
          group: "admin",
          type: "error",
          title: `Error: ${error.message}`,
        });
        this.error = JSON.stringify(error.message);
      },
    },
  },
};
</script>
<style scoped>
.businessLogoWrapper:hover > .btn {
  display: block;
}

@media screen and (max-width: 1280px) {
  body {
    box-sizing: border-box;
  }
}
</style>
