<template>
  <v-dialog
      v-model="dialog"
      width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="button d-none d-sm-flex" text
             v-bind="attrs"
             v-on="on"
      >
        Edit Business
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Edit Business
      </v-card-title>

      <v-form>
        <v-container>
          <v-row dense>
            <v-col
                cols="12"
            >
              <v-text-field
                  id="name"
                  v-model="name"
                  :required="true"
                  clearable
                  label="Name"
                  name="name"
              />
            </v-col>
            <v-spacer></v-spacer>
            <v-col
                cols="12"
            >
              <v-text-field v-for="(value,key) in social"
                            :id="key"
                            v-model="value.handle"
                            :hint="socialHint(value)"
                            :label="socialLabel(value)"
                            :name="value.type"
                            :placeholder="socialPlaceholder(value)"
                            :required="false"
                            class="mb-5"
                            clearable
                            persistent-hint
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            type="submit"
            @click="submit"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {INSERT_SOCIAL_LINKS, UPSERT_SOCIAL_LINKS} from "@/components/modals/graph/mutations";
import {UPDATE_BUSINESS} from "@/modules/business/graph/mutations";

export default {
  name: "EditBusinessModal",
  props: {
    business: {
      type: Object,
      required: true,
      default: () => {
      }
    }
  },
  data: function () {
    return {
      name: '',
      dialog: false,
      social: {
        TWITTER: {type: "TWITTER"},
        FACEBOOK: {type: "FACEBOOK"},
        INSTAGRAM: {type: "INSTAGRAM"},
        UNTAPPED: {type: "UNTAPPD"},
        WEBSITE: {type: "WEBSITE"},
      },
    };
  },
  watch: {
    business: {
      immediate: true,
      handler(newItem) {
        this.name = newItem?.name ? newItem?.name : '';
        newItem?.social && newItem.social.forEach(item => {
          this.social[item.type] = item
        })
      }
    }
  },
  methods: {
    socialHint(item) {
      const handle = item?.handle ? item.handle : ''
      switch (item.type) {
        case 'TWITTER':
          return `https://www.twitter.com/${handle}`
        case 'FACEBOOK':
          return `https://www.facebook.com/${handle}`
        case 'INSTAGRAM':
          return `https://www.instagram.com/${handle}`
        case 'UNTAPPD':
          return `https://www.untappd.com/v/${handle}`
        case 'WEBSITE':
          return `${handle ? handle : 'https://www.website.com'}`
        default:
          return 'Hint'
      }
    },
    socialLabel(item) {
      switch (item.type) {
        case 'TWITTER':
          return `Twitter`
        case 'FACEBOOK':
          return `Facebook`
        case 'INSTAGRAM':
          return `Instagram`
        case 'UNTAPPD':
          return `Untappd`
        case 'WEBSITE':
          return `Website`
        default:
          return 'Label'
      }
    },
    socialPlaceholder(item) {
      switch (item.type) {
        case 'TWITTER':
          return `Twitter`
        case 'FACEBOOK':
          return `@handle`
        case 'INSTAGRAM':
          return `@handle`
        case 'UNTAPPD':
          return `@handle/4473058`
        case 'Website':
          return `https://www.website.com`
        default:
          return '@handle'
      }
    },
    async submit() {
      const objectMap = Object.keys(this.social).map(key => this.social[key])

      let objects = objectMap.filter(item => item?.handle)
      objects.forEach(object => delete object.__typename)
      console.log(objects)
      try {
        // const objectsMap = objects.map(item=>({type: item.type, parent: this.$route.params.businessID, handle: item.handle}))
        // console.log(objectsMap)
        for (const object of objects) {
          if (object?.id) {

            await this.$apollo.mutate({
              mutation: UPSERT_SOCIAL_LINKS,
              variables: {
                objects: [object]
              }
            })
          } else {
            object.parent = this.$route.params.businessID
            await this.$apollo.mutate({
              mutation: INSERT_SOCIAL_LINKS,
              variables: {
                objects: [object]
              }
            })
          }
        }


        if (this.$props.business.name !== this.name) {
          await this.$apollo
              .mutate({
                mutation: UPDATE_BUSINESS,
                variables: {ID: this.route.params.businessID, _set: {name: this.name}},
              })
        }
        this.$notify({
          group: "admin",
          type: "success",
          title: "Business Updated",
        });
        this.$emit("refresh");
        this.dialog = false
      } catch (error) {
        console.log(error)
        this.$notify({
          group: "admin",
          type: "error",
          title: "Business Update Failed",
        });
      }
    }
  }
};
</script>