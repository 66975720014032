<template>
  <div class="layout">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "Layout",
};
</script>
