<template>
  <div>
    <div id="uppyDashboard"></div>
    <v-btn class="button d-none d-sm-flex" text @click="openUppy">
      {{ buttonLabel }}
    </v-btn>
  </div>
</template>
<script>
import {INSERT_IMAGES} from "@/components/images/graph/mutations";
import {getToken, upload} from "@/services/file.service";
import Uppy from "@uppy/core";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import Dashboard from "@uppy/dashboard";
import ImageKitUppyPlugin from "imagekit-uppy-plugin";

const STATUS_INITIAL = 0,
    STATUS_SAVING = 1,
    STATUS_SUCCESS = 2,
    STATUS_FAILED = 3;

export default {
  name: "ImageUpload",
  props: {
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: "Upload Image",
    },
    folderPath: {
      type: String,
      required: true,
      default: "/",
    },
  },
  data() {
    return {
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: "file",
      uppy: null,
    };
  },
  computed: {
    buttonLabel() {
      const userLabel = this.label !== "Upload Image";
      if (userLabel) {
        return this.label;
      } else {
        return this.multiple ? `${this.label}s` : this.label;
      }
    },
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
  },
  mounted() {
    this.reset();
    this.uppy = Uppy({
      debug: true,
      autoProceed: false,
      restrictions: {
        maxNumberOfFiles: this.multiple ? 10 : 1,
        maxFileSize: 25000000,
      },
    })
        .use(Dashboard, {
          inline: false,
          trigger: "#uppyDashboard", // your element
          proudlyDisplayPoweredByUppy: false,
          height: 300,
          width: 300,
          waitForThumbnailsBeforeUpload: true,
          closeModalOnClickOutside: true,
          metaFields: [
            {
              id: "name",
              name: "File name",
              placeholder: "Enter the file name",
            },
            {
              id: "folder",
              name: "Folder path",
              placeholder: "The destination path e.g. /website-assets",
            },
            {
              id: "tags",
              name: "Tags",
              placeholder: "Comma separated values",
            },
          ],
        })
        .use(ImageKitUppyPlugin, {
          id: "ImageKit",
          authenticationEndpoint: `https://beta.drinksy.app/api/imagekit`,
          publicKey: "public_6j9qxF2vMiK8c1j2SkP/6DGlqnU=",
          metaFields: [
            "useUniqueFileName",
            "tags",
            "folder",
            "isPrivateFile",
            "customCoordinates",
            "responseFields",
          ],
        });
    this.uppy.setMeta({folder: this.folderPath});
    this.uppy.on("complete", (result) => {
      const files = result.successful.map((file) => file.response.body);
      this.insertImages(files);
    });
  },
  methods: {
    openUppy() {
      this.uppy.getPlugin("Dashboard").openModal();
    },
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
    },
    save(formData) {
      // upload data to the server
      this.currentStatus = STATUS_SAVING;
      upload(formData)
          .then((x) => {
            this.uploadedFiles.push(x);
            this.currentStatus = STATUS_SUCCESS;
          })
          .catch((err) => {
            this.uploadError = err.response;
            this.currentStatus = STATUS_FAILED;
            this.$notify({
              group: "admin",
              type: "error",
              title: "Upload failed",
            });
          });
    },
    async filesChange(fieldName, fileList) {
      if (!fileList.length) return;

      const {data} = await getToken();
      const formData = new FormData();

      formData.append("file", fileList[0]);
      formData.append("publicKey", "public_6j9qxF2vMiK8c1j2SkP/6DGlqnU=");
      formData.append("signature", data.signature);
      formData.append("expire", data.expire);
      formData.append("token", data.token);
      formData.append("fileName", `${fileList[0].name}-logo`);
      formData.append("useUniqueFileName", false);

      this.save(formData);
    },
    insertImages(files) {
      const arrObj = files.map((file) => ({
        data: file,
        imagePath: file.filePath,
      }));
      this.$apollo
          .mutate({
            mutation: INSERT_IMAGES,
            variables: {
              objects: arrObj,
            },
          })
          .then(({data}) => {
            const ids = data.insert_image.returning.map((file) => file.id);
            this.$emit("save", ids);
            this.uppy.reset();
            this.uppy.getPlugin("Dashboard").closeModal();
          });
    },
  },
};
</script>
<style lang="css">
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
</style>
