<template>
  <v-dialog
      v-model="dialog"
      width="600"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn text class="button d-none d-sm-flex"
          v-bind="attrs"
          v-on="on"
      >
        {{ buttonName }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Add Location
      </v-card-title>
    <v-form ref="form">
      <v-container>
        <v-row>
          <v-col>
            <div class="text-h5">Info</div>
            <v-text-field v-model="name" label="Name"/>
            <v-text-field v-model="phone" label="Phone"/>
            <v-checkbox v-model="mainLocation" label="Main Location"></v-checkbox>
          </v-col>
          <v-col>
            <div class="text-h5">Address</div>
            <v-text-field v-model="street1" label="Street 1"/>
            <v-text-field v-model="street2" label="Street 2 (Optional)"/>
            <v-text-field v-model="city" label="City"/>
            <v-text-field v-model="state" label="State"/>
            <v-text-field v-model="postalCode" label="Postal Code"/>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="secondary"
            @click="cancel"
        >
          Cancel
        </v-btn>
        <v-btn v-if="!loading"
            color="primary"
            type="submit"
            @click="submit"
        >
          Submit
        </v-btn>
        <v-btn v-else
               color="primary"
               text
        >
          <v-progress-circular indeterminate :value="20"></v-progress-circular>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {
  INSERT_LOCATION,
  INSERT_ADDRESS,
} from "@/components/modals/graph/mutations";

export default {
  name: "AddLocationModal",
  props:{
    buttonName:{
      type:String,
      required:false,
      default:'Add Location'
    }
  },
  data: function () {
    return {
      loading:false,
      dialog:false,
      name:'',
      phone:null,
      street1:'',
      street2:'',
      city:'',
      state:'',
      postalCode:'',
      mainLocation:false,
    };
  },
  methods: {
    async createLocation() {
      this.loading = true
      const object = {
        object: {
          businessID: this.$route.params.businessID,
          name:this.name ? this.name : '',
          phoneNumber:this.phone ? this.phone : null,
          main:!!this.mainLocation
        },
      };

      const { data } = await this.$apollo.mutate({
        mutation: INSERT_LOCATION,
        variables: object,
      }).catch(()=>{this.loading = false})

      const addressObject = {
        object: {
          location_id:data.insert_location_one.id,
          address1:this.street1 ? this.street1 : '',
          address2:!this.street2 ? this.street2 : '',
          city:this.city ? this.city : '',
          state:this.state ? this.state : '',
          postalCode:this.postalCode ? this.postalCode : '',
        },
      };
      await this.$apollo.mutate({
        mutation: INSERT_ADDRESS,
        variables: addressObject,
      });
      this.loading = false
    },

    submit() {
      this.createLocation().then(() => {
        this.$emit("refetch");
        this.cancel()
      });
    },
    cancel () {
      this.$refs.form.reset()
      this.dialog = false
    },
  },
};
</script>

