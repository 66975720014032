import Vue from "vue";
import Vuex from "vuex";
import VueJwtDecode from "vue-jwt-decode";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});
export default new Vuex.Store({
  state: {
    token: "",
    user: {
      id: "",
      role: "user",
      email: "",
      firstName: "",
      lastName: "",
      profileImg: "",
    },
  },
  getters: {
    isAdmin: (state) => {
      return state.user.role === "admin";
    },
    getUserEmail: (state) => {
      return state.user.email || "";
    },
    getFullName: (state) => {
      return `${state.user.firstName} ${state.user.lastName}`;
    },
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload;
    },
    setUserRole(state, payload) {
      state.user.role = payload;
    },
    setUserID(state, payload) {
      state.user.id = payload;
    },
    setUser(state, payload) {
      state.user.email = payload.email;
      state.user.firstName = payload.given_name;
      state.user.lastName = payload.family_name;
      state.user.profileImg = payload.picture;
    },
  },
  actions: {
    userCheck({ commit }) {
      let token = window.localStorage.getItem("token");
      if (token) {
        const decoded = VueJwtDecode.decode(token);
        if (decoded.exp * 1000 < Date.now()) {
          window.localStorage.clear();

          commit("setUserRole", "user");
        }
      } else {
        commit("setUserRole", "user");
      }
    },
  },
  modules: {},
  plugins: [vuexLocal.plugin],
});
