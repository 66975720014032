<template>
  <v-dialog
      v-model="dialog"
      width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-bind="attrs"
          v-on="on"
      >
        Create Business
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Create Business
      </v-card-title>

      <v-form >
        <v-container>
          <v-row>
            <v-col
                cols="12"
                sm="6"
                md="6"
            >
        <v-text-field
            id="name"
            v-model="name"
            :required="true"
            name="name"
            label="Name"
        />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            type="submit"
            @click="submit"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {CREATE_BUSINESS} from "@/components/modals/graph/mutations";

export default {
  name: "CreateBusinessModal",
  data: function () {
    return {
      name: '',
      dialog: false
    };
  },
  methods: {
    submit() {
      this.$apollo
          .mutate({
            mutation: CREATE_BUSINESS,
            variables: {objects: {name: this.name}},
          })
          .then(() => {
            this.$notify({
              group: "admin",
              type: "success",
              title: "Business Created",
            });
            this.$emit("refresh");
            this.dialog = false
          })
          .catch(() => {
            this.$notify({
              group: "admin",
              type: "error",
              title: "Business Create Failed",
            });
          });
    },
  },
};
</script>