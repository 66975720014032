<template>
  <v-row v-if="socials.length > 0" class="lighten-2" justify="center">
    <v-col cols="12">
      <v-card class="pt-10 pb-10" color="#fafbfd" flat tile>
        <v-row dense justify="center">
          <v-col v-for="item in socials" v-if="!!item.handle && socialUrl(item)" cols="5" lg="2" md="2">
            <v-list-item-group class="d-flex wrap">
              <v-list-item class="justify-center">
                <v-icon class="mr-2">{{ socialIcon(item.type) }}</v-icon>
                <div class="text--darken-2"><a :href="socialUrl(item)" target="_blank">{{ item.type }}</a></div>
              </v-list-item>
            </v-list-item-group>
          </v-col>
        </v-row>
      </v-card>
      <v-card flat tile>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "SocialRow",
  props: {
    socials: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  methods: {
    socialUrl(item) {
      switch (item.type) {
        case 'TWITTER':
          return `https://twitter.com/${item.handle}`
        case 'FACEBOOK':
          return `https://facebook.com/${item.handle}`
        case 'INSTAGRAM':
          return `https://www.instagram.com/${item.handle}`
        case 'UNTAPPD':
          return `https://www.untappd.com/v/${item.handle}`
        default:
          return null
      }
    },
    socialIcon(type) {
      switch (type) {
        case 'TWITTER':
          return 'mdi-twitter'
        case 'FACEBOOK':
          return 'mdi-facebook'
        case 'INSTAGRAM':
          return 'mdi-instagram'
        case 'WEBSITE':
          return 'mdi-apple-safari'
        case 'UNTAPPD':
          return 'mdi-glass-mug-variant'
        default:
          return 'mdi-link-variant'
      }
    }
  }
}
</script>