import Vue from "vue";
import VueRouter from "vue-router";
import { authGuard } from "@/auth/authGuard";
import { default as adminRoutes } from "@/modules/admin/routes";

Vue.use(VueRouter);

const routes = [
  ...adminRoutes,
  {
    path: "/",
    name: "home",
    component: () => import("@/modules/businesses/Businesses"),
  },
  {
    path: "/upload",
    name: "upload",
    component: () => import("@/components/images/imageUpload"),
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/modules/profile/Profile"),
  },
  {
    path: "/map",
    name: "map",
    component: () => import("@/modules/map/Map"),
  },
  {
    path: "/callback",
    name: "callback",
    component: () => import("@/modules/Callback"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/modules/login/Login"),
    beforeEnter: authGuard,
  },
  {
    path: "/business/:businessID",
    name: "business",
    component: () => import("@/modules/business/Business"),
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
