<template>
  <v-footer
      color="mt-16"
      padless
  >
    <v-row
        justify="center"
        no-gutters
    >
<!--      <v-btn-->
<!--          v-for="link in links"-->
<!--          :key="link"-->
<!--          color="dark"-->
<!--          text-->
<!--          rounded-->
<!--          class="my-2"-->
<!--      >-->
<!--        {{ link }}-->
<!--      </v-btn>-->
      <v-col style="color: #ff3e00"
          class="py-4 text-center"
          cols="12"
      >
        {{ new Date().getFullYear() }} — <strong>Drinksy</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  data(){
    return{
      links: [
        'Home',
        'About Us',
        'Team',
        'Services',
        'Blog',
        'Contact Us',
      ],
    }
  }
}
</script>