export const formatPhone = (value) => {
  if (value.toString().length === 11) {
    return value
      .toString()
      .replace(/[^0-9]/g, "")
      .replace(/(\d)(\d{3})(\d{3})(\d{4})/, "$1 ($2) $3-$4");
  } else if (value.toString().length === 10) {
    return value
      .toString()
      .replace(/[^0-9]/g, "")
      .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  } else {
    return value;
  }
};
