import gql from "graphql-tag";

export const UPDATE_BUSINESS_LOGO = gql`
  mutation UpdateBusinessLogo($id: uuid!, $logo: uuid) {
    update_business_by_pk(pk_columns: { id: $id }, _set: { logoID: $logo }) {
      id
    }
  }
`;

export const UPDATE_BUSINESS = gql`
  mutation updateBusiness($id: uuid!, $_set: business_set_input!) {
    update_business_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
  }
`;

export const UPDATE_ADDRESS = gql`
  mutation updateAddress($id: uuid!, $_set: address_set_input) {
    update_address_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
  }
`;

export const UPDATE_DISABLE_BUSINESS = gql`
  mutation disableBusiness($id: uuid!, $closed: Boolean) {
    update_business_by_pk(pk_columns: { id: $id }, _set: { closed: $closed }) {
      id
    }
  }
`;

export const UPDATE_DISABLE_LOCATION = gql`
  mutation disableLocation($id: uuid!, $closed: Boolean) {
    update_location_by_pk(pk_columns: { id: $id }, _set: { closed: $closed }) {
      id
    }
  }
`;

export const DELETE_IMAGE = gql`
  mutation deleteImage($id: uuid!) {
    delete_image_by_pk(id: $id) {
      id
    }
  }
`;
