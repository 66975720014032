<template>
  <Fragment>
    <v-row class="no-gutters" style="max-height:200px; height:100%">
      <v-row
          :style="{position: 'absolute', width:'100%', backgroundImage:`url(${heroImg})`, maxHeight:'200px', height:'100%', filter: 'blur(4px)'}"
          class="no-gutters"></v-row>
    </v-row>
    <template v-if="imagePath">
      <v-row class="pl-5 pr-5 no-gutters mb-6 mt-16" justify-sm="center"
             style="height: 200px; position: relative">
        <v-col class="text-center" cols="12">
          <v-img :alt="business.name" :src="imagePath" contain max-height="200px"
                 width="auto"></v-img>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row class="pl-5 pr-5 no-gutters mb-6 mt-16" justify-sm="center" style="height: 200px; position: relative">
        <v-col class="text-center" cols="12">
          <typography class="text-h2 white--text">{{ business.name }}</typography>
        </v-col>
      </v-row>
    </template>
    <!--    <v-row>-->
    <!--      <v-btn icon @click="favoriteHandler"><v-icon>mdi-heart-outline</v-icon></v-btn>-->
    <!--      <v-btn icon color="pink"><v-icon>mdi-heart</v-icon></v-btn>-->
    <!--      <v-btn icon @click="visitedHandler"><v-icon>mdi-map-marker-outline</v-icon></v-btn>-->
    <!--      <v-btn icon color="green"><v-icon>mdi-map-marker-check-outline</v-icon></v-btn>-->
    <!--    </v-row>-->
  </Fragment>
</template>

<script>
export default {
  name: "BusinessHeader",
  props: {
    business: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      heroImg: require("@/assets/images/header.jpg"),
    };
  },
  computed: {
    imagePath() {
      return this.business?.logo?.imageUrl ? this.business?.logo?.imageUrl : null
    },
  },
  methods: {
    favoriteHandler(bool) {
      return bool
    },
    visitedHandler(bool) {
      return bool
    }
  },
  apollo: {
    // userActions
  }
};
</script>